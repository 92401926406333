import {
  GET_ALL_PACKAGES_SUCCESS,
  GET_ALL_PACKAGES_FAIL,
  CREATE_NEW_PACKAGE_SUCCESS,
  CREATE_NEW_PACKAGE_FAIL,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAIL,
  UPDATE_PACKAGE
} from "./types";

const initialState = {
  allPackages: [],
  error: "",
  success: "",
};

const Packages = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_PACKAGES_SUCCESS:
      return { ...state, allPackages: payload, error: "", success: "" };
    case GET_ALL_PACKAGES_FAIL:
      return { ...state, error: payload, success: "" };
    case CREATE_NEW_PACKAGE_SUCCESS:
      return { ...state, error: "", success: payload };
    case CREATE_NEW_PACKAGE_FAIL:
      return { ...state, error: payload, success: "" };
    case DELETE_PACKAGE_SUCCESS:
      case UPDATE_PACKAGE:
        return {
          ...state,
          allPackages: state.allPackages.map((pack) =>
            pack._id === action.payload.packageId ? { ...pack, ...action.payload.updatedData } : pack
          ),
        };
      return {
        ...state,
        error: "",
        success: "Package deleted successfully",
        allPackages: state.allPackages.filter(
          (packages) => packages._id !== payload
        ),
      };

    default:
      return state;
  }
};

export default Packages;
