import { lazy } from "react";

const publicRoutes = [
  {
    Component: lazy(() => import("../auth/Login")),
    Title: "Login",
    Path: "login",
  },
  {
    Component: lazy(() => import("../auth/Unauth404")),
    Title: "404 Error",
    Path: "*",
  },
];

export default publicRoutes;
