import { useEffect } from "react";
import { categorySpreader, breadcrumbSpreader } from "../redux/actions";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

function RoutesPropsSpreader({ category, breadcrumb }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(categorySpreader(category));
  }, [category, dispatch]);
  useEffect(() => {
    dispatch(breadcrumbSpreader(breadcrumb));
  });
  return <Outlet />;
}

export default RoutesPropsSpreader;
