import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setThroughSearch } from "../../redux/actions";
import { useNavigate } from "react-router-dom";

function HeaderSearch() {
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setThroughSearch(searchQuery));
  }, [dispatch, searchQuery]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter" && e.target.classList.contains("search-input")) {
        e.preventDefault();
        navigate(`/user/all-users?q=${encodeURIComponent(searchQuery)}`);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [navigate, searchQuery]);

  return (
    <div className="app-navbar-item ms-2 ms-lg-4">
      <div
        className="header-search d-flex align-items-center w-lg-250px"
        title="Search a user by name"
      >
        <div
          data-kt-search-element="toggle"
          className="d-flex d-lg-none align-items-center"
        ></div>
        <form
          data-kt-search-element="form"
          className="d-lg-block w-100 position-relative  mb-lg-0"
          autoComplete="off"
        >
          <input type="hidden" />
          <span className="svg-icon svg-icon-2 svg-icon-lg-3 svg-icon-gray-800 position-absolute top-50 translate-middle-y ms-3">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* SVG path here */}
            </svg>
          </span>
          <input
            type="text"
            className="search-input form-control form-control-solid ps-13"
            name="search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search..."
            data-kt-search-element="input"
          />
          <span
            className="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5"
            data-kt-search-element="spinner"
          >
            <span className="spinner-border h-15px w-15px align-middle text-gray-400"></span>
          </span>
          <span
            className="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4"
            data-kt-search-element="clear"
          >
            <span className="svg-icon svg-icon-2 svg-icon-lg-1 me-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* SVG path here */}
              </svg>
            </span>
          </span>
        </form>
      </div>
    </div>
  );
}

export default HeaderSearch;
