import axios from "axios";
import axiosAuth from "../axios";
import {
  GET_ALL_PACKAGES_SUCCESS,
  GET_ALL_PACKAGES_FAIL,
  CREATE_NEW_PACKAGE_SUCCESS,
  CREATE_NEW_PACKAGE_FAIL,
  DELETE_PACKAGE_SUCCESS,
  DELETE_PACKAGE_FAIL,
  UPDATE_PACKAGE
} from "./types";

export const getAllPackages = () => async (dispatch) => {
  try {
    const res = await axios.get(
      process.env.REACT_APP_ADMIN_BASE_URL_SERVER + "/package/all-packages"
    );
    dispatch({
      type: GET_ALL_PACKAGES_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PACKAGES_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const createNewPackage = (body, navigate) => async (dispatch) => {
  try {
    const res = await axiosAuth.post("/package/new-package", body);

    dispatch({
      type: CREATE_NEW_PACKAGE_SUCCESS,
      payload: res.data.msg,
    });
    dispatch(getAllPackages());
    navigate("/package/all-packages");
  } catch (error) {
    dispatch({
      type: CREATE_NEW_PACKAGE_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const deletePackage = (id) => async (dispatch) => {
  try {
    await axiosAuth.delete(`package/${id}`);

    dispatch({
      type: DELETE_PACKAGE_SUCCESS,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PACKAGE_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};
export const updatePackage = (packageId, updatedData) => async (dispatch) => {
  try {
    const response = await axios.patch( process.env.REACT_APP_ADMIN_BASE_URL_SERVER +`/package/update-package/${packageId}`, updatedData);
    console.log(response.data); // Optional: Log the response or handle it as needed
    dispatch({
      type: UPDATE_PACKAGE,
      payload: { packageId, updatedData },
    });
  } catch (error) {
    console.log(error.response.data); // Optional: Handle the error response
  }
};
