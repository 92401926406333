import React from "react";
import { Link } from "react-router-dom";
import { logout } from "../../redux/actions";
import { useDispatch } from "react-redux";

function Logout() {
  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(logout());
  };
  return (
    <div className="app-navbar-item ms-2 ms-lg-4">
      <Link
        to="/login"
        className="btn btn-icon btn-light-danger fw-bold"
        title="Logout"
        onClick={onLogout}
      >
        <i className="bi bi-box-arrow-right fs-6"></i>
      </Link>
    </div>
  );
}

export default Logout;
