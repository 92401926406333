import {
  GET_ALL_IMAGE_APPROVALS,
  GET_ALL_APPROVALS,
  IMAGE_APPROVE_SUCCESS,
  IMAGE_APPROVE_FAIL,
  IMAGE_DELETE_SUCCESS,
  IMAGE_DELETE_FAIL,
  ALL_DATA_APPROVE_SUCCESS,
  ALL_DATA_APPROVE_FAIL,
} from "./types";
import axios from "../axios";

export const getAllProfileImageApprovals = () => async (dispatch) => {
  try {
    const res = await axios.get("/approval/all-image-approvals");
    dispatch({
      type: GET_ALL_IMAGE_APPROVALS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getAllApprovals = () => async (dispatch) => {
  try {
    const res = await axios.get("/approval/all-approvals");
    // console.log(res);
    dispatch({
      type: GET_ALL_APPROVALS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const imageApprovedSuccess = (id) => async (dispatch) => {
  try {
    await axios.get(`/approval/update-approval/${id}`);
    dispatch({
      type: IMAGE_APPROVE_SUCCESS,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: IMAGE_APPROVE_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const deleteApprovalSuccess = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/approval/delete-approval/${id}`);

    dispatch({
      type: IMAGE_DELETE_SUCCESS,
      payload: id,
    });
    console.log(res);
  } catch (error) {
    dispatch({
      type: IMAGE_DELETE_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const allDataApproval = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/approval/by-admin/approve-data/${id}`);

    dispatch({
      type: ALL_DATA_APPROVE_SUCCESS,
      payload: { res: res.data, approvalId: id },
    });
  } catch (error) {
    dispatch({
      type: ALL_DATA_APPROVE_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};
