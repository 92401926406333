import {
  GET_SAMMELAN_SUCCESS,
  GET_SAMMELAN_FAIL,
  ADD_NEW_SAMMELAN_SUCCESS,
  ADD_NEW_SAMMELAN_FAIL,
  SAMMELAN_DELETE_FAILED,
  SAMMELAN_DELETE_SUCCESS,
  SAMMELAN_UPDATED_SUCCESS,
  SAMMELAN_UPDATED_FAILED,
  SET_SELECTED_SAMMELAN,
  GET_SELECTED_SAMMELAN_SUCCESS,
  GET_SELECTED_SAMMELAN_FAILURE,
} from "./types";

const initialState = {
  allSammelans: null,
  error: "",
  success: "",
  selectedSammelan: null,
};

const Sammelan = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    
    case GET_SAMMELAN_SUCCESS:
      return {
        ...state,
        allSammelans: payload,
      };
    case GET_SAMMELAN_FAIL:
      return { ...state, error: payload };
    case ADD_NEW_SAMMELAN_SUCCESS:
      return {
        ...state,
        error: "",
        success: payload.msg,
        allSammelans: [...state.allSammelans, { ...payload.data }],
      };
    case SAMMELAN_UPDATED_SUCCESS:
      const updatedArray = state.allSammelans.map((sammelan) => {
        if (sammelan._id === payload.data._id) {
          return { ...sammelan, ...payload.data };
        }
        return sammelan;
      });
      return {
        ...state,
        error: "",
        success: payload.msg,
        allSammelans: updatedArray,
      };
    case SAMMELAN_DELETE_SUCCESS:
      return {
        ...state,
        error: "",
        success: payload.msg,
        allSammelans: state.allSammelans.filter(
          (sammelan) => sammelan._id !== payload.id
        ),
      };





      case SET_SELECTED_SAMMELAN:
        return {
          ...state,
          selectedSammelan: action.payload,
        };
      case GET_SELECTED_SAMMELAN_SUCCESS:
        return {
          ...state,
          selectedSammelan: action.payload,
          error: null,
        };
      case GET_SELECTED_SAMMELAN_FAILURE:
        return {
          ...state,
          selectedSammelan: null,
          error: action.payload,
        };



        
    case SAMMELAN_UPDATED_FAILED:
      return { ...state, error: payload, success: "" };
    case ADD_NEW_SAMMELAN_FAIL:
      return { ...state, error: payload, success: "" };
    case SAMMELAN_DELETE_FAILED:
      return { ...state, error: payload, success: "" };
    default:
      return state;
  };
  
  
};




export default Sammelan;
