import { combineReducers } from "redux";
import Layout from "./Layout/reducers";
import Sammelan from "./Sammelan/reducers";
import Auth from "./Auth/reducers";
import Users from "./Users/reducers";
import Packages from "./Packages/reducers";
import CMS from "./CMS/reducers";
import Staff from "./Staff/reducers";
import Approvals from "./Approvals/reducers";

export default combineReducers({
  Layout,
  Sammelan,
  Auth,
  Users,
  Packages,
  CMS,
  Staff,
  Approvals,
});
