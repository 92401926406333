import { lazy } from "react";

export const privateRoutes = [
  {
    Component: lazy(() => import("../pages/Dashboard")),
    Path: "/",
    Title: "Dashboard",
    Category: "dashboard",
    Breadcrumb: {
      heading: "Dashboard",
      subHeading: "",
    },
  },
  {
    Component: lazy(() => import("../pages/Dashboard")),
    Path: "dashboard",
    Title: "Dashboard",
    Category: "dashboard",
    Breadcrumb: {
      heading: "Dashboard",
      subHeading: "",
    },
  },
  {
    Component: lazy(() => import("../pages/Sammelan")),
    Path: "sammelan",
    Title: "Sammelan",
    Category: "sammelan",
    Breadcrumb: {
      heading: "Sammelan",
      subHeading: "",
    },
  },
  {
    Component: lazy(() => import("../pages/DeleteSammelanUser")),
    Path: "deleteSammelanUsers",
    Title: "Delete Sammelan Users",
    Category: "sammelan",
    Breadcrumb: {
      heading: "Delete Sammelan Users",
      subHeading: "",
    },
  },
  // User Info /////////////////////////////////////////////////
  // Today's Users
  {
    Component: lazy(() => import("../pages/user/TodaysUsers")),
    Path: "user/todays-users",
    Title: "Today's Users",
    Category: "users-info",
    Breadcrumb: {
      heading: "Users Info",
      subHeading: "Today's Users",
    },
  },

  // Online Users
  {
    Component: lazy(() => import("../pages/user/OnlineUsers")),
    Path: "user/online-users",
    Title: "Online Users",
    Category: "users-info",
    Breadcrumb: {
      heading: "Users Info",
      subHeading: "Online Users",
    },
  },

  // UnderReview users
  {
    Component: lazy(() => import("../pages/user/UnderReviewUsers")),
    Path: "user/under-review",
    Title: "Users Under Review",
    Category: "users-info",
    Breadcrumb: {
      heading: "Users Info",
      subHeading: "Under Review",
    },
  },

  // All Users
  {
    Component: lazy(() => import("../pages/user/AllUsers")),
    Path: "user/all-users",
    Title: "All Users",
    Category: "users-info",
    Breadcrumb: {
      heading: "Users Info",
      subHeading: "All Users",
    },
  },

  // User Profile view
  {
    Component: lazy(() => import("../pages/user/UserProfileView")),
    Path: "user/profile-view/:userId/:gender/:underReview",
    Title: "User Profile",
    Category: "users-info",
    Breadcrumb: {
      heading: "Users Info",
      subHeading: "Profile Overview",
    },
  },
  // User Profile Print
  {
    Component: lazy(() => import("../pages/user/userProfilePrint")),
    Path: "user/profile-print/:id",
    Title: "User Profile Print",
    Category: "users-info",
    Breadcrumb: {
      heading: "Users Profile Print",
      subHeading: "Profile Print",
    },
  },

  // PACKAGES
  {
    Component: lazy(() => import("../pages/package/NewPackage")),
    Path: "package/new-package",
    Title: "New Package",
    Category: "packages",
    Breadcrumb: {
      heading: "Packages",
      subHeading: "New Package",
    },
  },
  {
    Component: lazy(() => import("../pages/package/AllPackages")),
    Path: "package/all-packages",
    Title: "All Packages",
    Category: "packages",
    Breadcrumb: {
      heading: "Packages",
      subHeading: "All Packages",
    },
  },

  // Payments /////////////////////////////////////////////////////////////////////
  // Successful
  {
    Component: lazy(() => import("../pages/payments/Successful")),
    Path: "payments/successful",
    Title: "Successful",
    Category: "payments",
    Breadcrumb: {
      heading: "Successful",
      subHeading: "payments",
    },
  },

  // Pending
  {
    Component: lazy(() => import("../pages/payments/Pending")),
    Path: "payments/pending",
    Title: "Pending",
    Category: "payments",
    Breadcrumb: {
      heading: "Pending",
      subHeading: "payments",
    },
  },

  // Rejected
  {
    Component: lazy(() => import("../pages/payments/Rejected")),
    Path: "payments/rejected",
    Title: "Rejected",
    Category: "payments",
    Breadcrumb: {
      heading: "Rejected",
      subHeading: "payments",
    },
  },

  // Total Payments
  {
    Component: lazy(() => import("../pages/payments/TotalPayments")),
    Path: "payments/total-payments",
    Title: "Total Payments",
    Category: "payments",
    Breadcrumb: {
      heading: "Total Payments",
      subHeading: "payments",
    },
  },

  // Website CMS /////////////////////////////////////////////
  // Slider Images
  {
    Component: lazy(() => import("../pages/websiteCms/SliderImages")),
    Path: "/websiteCms/SliderImages",
    Title: "Slider Images",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Slider Images",
      subHeading: "website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/SuccessStories")),
    Path: "/websiteCms/SuccessStories",
    Title: "Success Stories",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Success Stories",
      subHeading: "website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/EventPopup")),
    Path: "/websiteCms/EventPopup",
    Title: "Success Stories",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Event Popup",
      subHeading: "website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/PrivacyPolicy")),
    Path: "/websiteCms/PrivacyPolicy",
    Title: "Privacy Policy",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Privacy Policy",
      subHeading: "website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/TermsAndCondition")),
    Path: "/websiteCms/TermsAndCondition",
    Title: "Terms And Conditions",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Terms And Conditions",
      subHeading: "website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/Refunds")),
    Path: "/websiteCms/Refunds",
    Title: "Refunds",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Refunds",
      subHeading: "website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/Disclaimer")),
    Path: "/websiteCms/Disclaimer",
    Title: "Disclaimer",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Disclaimer",
      subHeading: "website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/ReportAndMisuse")),
    Path: "/websiteCms/ReportAndMisuse",
    Title: "Report And Misuse",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Report And Misuse",
      subHeading: "website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/Contact")),
    Path: "/websiteCms/Contact",
    Title: "Contact",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Contact",
      subHeading: "website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/About")),
    Path: "/websiteCms/About",
    Title: "About",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "About",
      subHeading: "website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/BankDetails")),
    Path: "/websiteCms/BankDetails",
    Title: "Bank Details",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Bank Details",
      subHeading: "website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/Event")),
    Path: "/websiteCms/Event",
    Title: "Event",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Event",
      subHeading: "website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/Faq")),
    Path: "/websiteCms/Faq",
    Title: "Faq",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Faq",
      subHeading: "website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/SocialLinks")),
    Path: "/websiteCms/SocialLinks",
    Title: "Social Links",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Social Links",
      subHeading: "website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/Testimonial")),
    Path: "/websiteCms/Testimonial",
    Title: "Testimonial",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Testimonial",
      subHeading: "website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/Team")),
    Path: "/websiteCms/Team",
    Title: "Team",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Team",
      subHeading: "website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/DownloadMobile")),
    Path: "/websiteCms/DownloadMobile",
    Title: "Download Mobile",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Download Mobile",
      subHeading: "website Cms",
    },
  },
  
  {
    Component: lazy(() => import("../pages/websiteCms/FactsAndFigures")),
    Path: "/websiteCms/FactsAndFigures",
    Title: "Facts And Figures",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Facts And Figures",
      subHeading: "Website Cms",
    },
  },
  {
    Component: lazy(() => import("../pages/websiteCms/SuccessStoryHome")),
    Path: "/websiteCms/SuccessStoryHome",
    Title: "Succes Story Home Page Images",
    Category: "websiteCms",
    Breadcrumb: {
      heading: "Succes Story Home Page Images",
      subHeading: "Website Cms",
    },
  },
  // User Profile Activity ///////////////////////////////////////////////////////////
  // Activity Log
  {
    Component: lazy(() => import("../pages/user-profile-activity/ActivityLog")),
    Path: "user-profile-activity/ActivityLog",
    Title: "Activity Log",
    Category: "user-profile-activity",
    Breadcrumb: {
      heading: "Activity Log",
      subHeading: "User Profile Activity",
    },
  },
  // Activity Log
  {
    Component: lazy(() =>
      import("../pages/user-profile-activity/DeletionRequest")
    ),
    Path: "user-profile-activity/DeletionRequest",
    Title: "Deletion Request",
    Category: "user-profile-activity",
    Breadcrumb: {
      heading: "Deletion Request",
      subHeading: "User Profile Activity",
    },
  },

  // Member Data Approval ////////////////////////////////////////////////////////////
  // User profile photo approval
  {
    Component: lazy(() =>
      import("../pages/member-data-approval/UserProfilePhotoApproval")
    ),
    Path: "member-data-approval/UserProfilePhotoApproval",
    Title: "UserProfilePhotoApproval",
    Category: "UserProfilePhotoApproval",
    Breadcrumb: {
      heading: "UserProfilePhotoApproval",
      subHeading: "",
    },
  },

  // User Gallery Approval
  {
    Component: lazy(() =>
      import("../pages/member-data-approval/UserGalleryApproval")
    ),
    Path: "member-data-approval/UserGalleryApproval",
    Title: "User Gallery Approval",
    Category: "UserGallery Approval",
    Breadcrumb: {
      heading: "User Gallery Approval",
      subHeading: "",
    },
  },
  
  {
    Component: lazy(() =>
      import("../pages/member-data-approval/UserGalleryPage")
    ),
    Path: "member-data-approval/UserGalleryPage/:userId",
    Title: "UserGalleryPage",
    Category: "UserGalleryPage",
    Breadcrumb: {
      heading: "UserGalleryPage",
      subHeading: "",
    },
  },

  // User All Data Approval
  {
    Component: lazy(() =>
      import("../pages/member-data-approval/UserAllDataApproval")
    ),
    Path: "member-data-approval/UserAllDataApproval",
    Title: "UserAllDataApproval",
    Category: "UserAllDataApproval",
    Breadcrumb: {
      heading: "UserAllDataApproval",
      subHeading: "UserAllDataApproval",
    },
  },
  {
    Component: lazy(() =>
      import("../pages/member-data-approval/ApprovalDataConfirmation")
    ),
    Path: "member-data-approval/UserAllDataApproval/:id",
    Title: "UserAllDataApproval",
    Category: "UserAllDataApproval",
    Breadcrumb: {
      heading: "UserAllDataApproval",
      subHeading: "UserAllDataApproval",
    },
  },

  // User Attributes //////////////////////////////////////////////////////////////////
  // Caste
  {
    Component: lazy(() => import("../pages/user-attribute/Caste")),
    Path: "user-attribute/caste",
    Title: "Caste",
    Category: "user-attribute",
    Breadcrumb: {
      heading: "Caste",
      subHeading: "User Attribute",
    },
  },
  // Gotra
  {
    Component: lazy(() => import("../pages/user-attribute/Gotra")),
    Path: "user-attribute/gotra",
    Title: "Gotra",
    Category: "user-attribute",
    Breadcrumb: {
      heading: "Gotra",
      subHeading: "User Attribute",
    },
  },
  {
    Component: lazy(() => import("../pages/ManageStaff")),
    Path: "manage-staff",
    Title: "Staff Management",
    Category: "staff",
    Breadcrumb: {
      heading: "Staff Management",
      subHeading: "Staff",
    },
  },

  // All Reports /////////////////////////////////////////////////////////
  // Top Profile Views
  {
    Component: lazy(() => import("../pages/all-reports/Top-profile-views")),
    Path: "all-reports/top-profile-views",
    Title: "Top Profile Views",
    Category: "all-reports",
    Breadcrumb: {
      heading: "Top Profile Views",
      subHeading: "All Reports",
    },
  },
  // contact messages
  {
    Component: lazy(() => import("../pages/all-reports/Contact-messages")),
    Path: "all-reports/contact-messages",
    Title: "Contact Messages",
    Category: "all-reports",
    Breadcrumb: {
      heading: "Contact Messages",
      subHeading: "All Reports",
    },
  },
  // User Session
  {
    Component: lazy(() => import("../pages/all-reports/User-session")),
    Path: "all-reports/User-Session",
    Title: "User Session",
    Category: "all-reports",
    Breadcrumb: {
      heading: "User Session",
      subHeading: "All Reports",
    },
  },
  // User Activity
  {
    Component: lazy(() => import("../pages/all-reports/User-activity")),
    Path: "all-reports/User-activity",
    Title: "User Activity",
    Category: "all-reports",
    Breadcrumb: {
      heading: "User Activity",
      subHeading: "All Reports",
    },
  },
  
  {
    Component: lazy(() => import("../pages/all-reports/User-data-log")),
    Path: "all-reports/User-data-log",
    Title: "User Data Log",
    Category: "all-reports",
    Breadcrumb: {
      heading: "User Data Log",
      subHeading: "All Reports",
    },
  },
  {
    Component: lazy(() => import("../pages/user/shakhaUpdate")),
    Path: "shakha-update",
    Title: "Shakha Update",
    Category: "all-reports",
    Breadcrumb: {
      heading: "Shakha Update",
      subHeading: "Shakha Update",
    },
  },
 






];
