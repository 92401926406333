// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-gray-200 {
  background-color: var(--kt-gray-200);
}

.text-gray-400 {
  color: var(--kt-text-gray-400) !important;
}
.text-gray-700 {
  color: var(--kt-text-gray-700) !important;
}

.text-gray-800 {
  color: var(--kt-text-gray-800) !important;
}

.app-text-primary {
  color: var(--kt-primary);
}
.app-bg-primary {
  background-color: var(--kt-primary);
}
.bg-light-primary {
  background-color: var(--kt-primary-light) !important;
}

.app-text-danger {
  color: var(--kt-danger);
}

.app-bg-danger {
  background-color: var(--kt-danger);
}
.app-text-success {
  color: var(--kt-success);
}

.app-bg-success {
  background-color: var(--kt-success);
}
.app-text-warning {
  color: var(--kt-warning);
}

.app-bg-warning {
  background-color: var(--kt-warning);
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/backgrounds.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,yCAAyC;AAC3C;AACA;EACE,yCAAyC;AAC3C;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,wBAAwB;AAC1B;AACA;EACE,mCAAmC;AACrC;AACA;EACE,oDAAoD;AACtD;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kCAAkC;AACpC;AACA;EACE,wBAAwB;AAC1B;;AAEA;EACE,mCAAmC;AACrC;AACA;EACE,wBAAwB;AAC1B;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":[".bg-gray-200 {\n  background-color: var(--kt-gray-200);\n}\n\n.text-gray-400 {\n  color: var(--kt-text-gray-400) !important;\n}\n.text-gray-700 {\n  color: var(--kt-text-gray-700) !important;\n}\n\n.text-gray-800 {\n  color: var(--kt-text-gray-800) !important;\n}\n\n.app-text-primary {\n  color: var(--kt-primary);\n}\n.app-bg-primary {\n  background-color: var(--kt-primary);\n}\n.bg-light-primary {\n  background-color: var(--kt-primary-light) !important;\n}\n\n.app-text-danger {\n  color: var(--kt-danger);\n}\n\n.app-bg-danger {\n  background-color: var(--kt-danger);\n}\n.app-text-success {\n  color: var(--kt-success);\n}\n\n.app-bg-success {\n  background-color: var(--kt-success);\n}\n.app-text-warning {\n  color: var(--kt-warning);\n}\n\n.app-bg-warning {\n  background-color: var(--kt-warning);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
