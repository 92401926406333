import {
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  USER_LOADED,
  AUTH_ERROR,
} from "./types";

const initialState = {
  error: "",
  token: localStorage.token || null,
  isAuthenticated: localStorage.token ? true : false,
  currentUser: null,
};

const Auth = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_LOADED:
      return { ...state, isAuthenticated: true };
    case ADMIN_LOGIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
        currentUser: payload.data,
      };
    case GET_CURRENT_USER_SUCCESS:
      return { ...state, currentUser: payload };
    case GET_CURRENT_USER_FAIL:
      localStorage.removeItem("token");
      return {
        ...state,
        currentUser: null,
        token: null,
        isAuthenticated: false,
        error: payload,
      };
    case ADMIN_LOGIN_FAIL:
      return {
        ...state,
        error: payload,
        isAuthenticated: false,
        token: null,
        currentUser: null,
      };
    case LOGOUT_SUCCESS:
      localStorage.removeItem("token");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        error: "",
        currentUser: null,
      };
    case AUTH_ERROR:
      localStorage.removeItem("token");
      return {
        ...state,
        error: payload,
        isAuthenticated: false,
        token: null,
        currentUser: null,
      };
    case LOGOUT_FAIL:
      return { ...state, error: payload };
    default:
      return state;
  }
};

export default Auth;
