import "../assets/styles/Header.css";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import HeaderNotification from "./partials/HeaderNotification";
import HeaderSearch from "./partials/HeaderSearch";
import Logout from "./partials/Logout";
import './partials/headerStyling.css';

function Header() {
  return (
    <>
      <div className="d-flex flex-row justify-content-between p-3">
        <div
          className="d-flex align-items-center d-lg-none ms-n2 me-2"
          title="Show sidebar menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="kt_app_sidebar_mobile_toggle"
          >
            {/* <span className="svg-icon svg-icon-1">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                fill="currentColor"
              ></path>
              <path
                opacity="0.3"
                d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                fill="currentColor"
              ></path>
            </svg>
          </span> */}
          </div>
        </div>

        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 justify-content-end">
          <Link to="/" className="d-lg-none">
            <span style={{ backgroundColor: 'red', color: 'white', borderRadius: '20px', padding: '10px', fontSize: '1rem' }}>
              Olakh
            </span>
          </Link>
        </div>
      </div>

      <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1 search-mob">
        {/* Breadcrumb */}
        <Breadcrumb />

        <div className="app-navbar align-items-center flex-shrink-0 mb-3">
          {/* Search */}
          <HeaderSearch />
          {/* Notifications */}
          {/* <HeaderNotification /> */}
          {/* Logout */}
          <Logout />
        </div>
      </div>
    </>
  );
}

export default Header;
