import {
  GET_ONLINE_USERS_SUCCESS,
  GET_ONLINE_USERS_FAIL,
  GET_UNDER_REVIEW_USERS_SUCCESS,
  GET_UNDER_REVIEW_USERS_FAIL,
  GET_PROFILE_VIEW_USERS_SUCCESS,
  GET_PROFILE_VIEW_USERS_FAIL,
  USER_VERIFY_SUCCESS,
  USER_VERIFY_FAIL,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  UPDATE_PROFILE_VIEW_USERS_SUCCESS,
  UPDATE_PROFILE_VIEW_USERS_FAIL,
  GET_TODAY_USERS_SUCCESS,
  GET_TODAY_USERS_FAIL,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_FAIL,
} from "./types";

const initialState = {
  onlineUsers: null,
  underReviewUsers: null,
  userInProfileView: null,
  allUsers: null,
  todayUsers: null,
  error: "",
  success: "",
};

const Users = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ONLINE_USERS_SUCCESS:
      return {
        ...state,
        onlineUsers: payload,
        error: "",
        success: "",
      };
    case GET_ONLINE_USERS_FAIL:
      return { ...state, onlineUsers: null, error: payload, success: "" };
    case GET_UNDER_REVIEW_USERS_SUCCESS:
      return { ...state, underReviewUsers: payload, error: "", success: "" };
    case GET_UNDER_REVIEW_USERS_FAIL:
      return { ...state, error: payload, success: "" };
    case UPDATE_PROFILE_VIEW_USERS_SUCCESS:
    case GET_PROFILE_VIEW_USERS_SUCCESS:
      return { ...state, userInProfileView: payload, error: "", success: "" };
    case UPDATE_PROFILE_VIEW_USERS_FAIL:
    case GET_PROFILE_VIEW_USERS_FAIL:
      return { ...state, userInProfileView: null, error: payload, success: "" };
    case USER_VERIFY_SUCCESS:
      return { ...state, error: "", success: payload };
    case USER_VERIFY_FAIL:
      return { ...state, error: payload, success: "" };
    case GET_ALL_USERS_SUCCESS:
      return { ...state, allUsers: payload, error: "", success: "" };
    case GET_ALL_USERS_FAIL:
      return { ...state, error: payload, success: "" };
    case GET_TODAY_USERS_SUCCESS:
      return { ...state, error: "", todayUsers: payload };
    case GET_TODAY_USERS_FAIL:
      return { ...state, error: payload };
    case DELETE_USERS_SUCCESS:
      return {
        ...state,
        error: "",
        allUsers: state.allUsers.filter((user) => user._id !== payload),
        todayUsers: state.todayUsers.filter((user) => user._id !== payload),
        onlineUsers: state.onlineUsers.filter((user) => user._id !== payload),
        underReviewUsers: state.underReviewUsers.filter(
          (user) => user._id !== payload
        ),
        success: "User deleted successfully",
      };
    case DELETE_USERS_FAIL:
      return { ...state, error: payload };
    default:
      return state;
  }
};

export default Users;
