export const CMS_FILES_UPLOAD_SUCCESS = "CMS_FILES_UPLOAD_SUCCESS";
export const CMS_FILES_UPLOAD_FAIL = "CMS_FILES_UPLOAD_FAIL";
export const CMS_FILES_GET_SUCCESS = "CMS_FILES_GET_SUCCESS";
export const CMS_FILES_GET_FAIL = "CMS_FILES_GET_FAIL";
export const CMS_FILES_DELETE_SUCCESS = "CMS_FILES_DELETE_SUCCESS";
export const CMS_FILES_DELETE_FAIL = "CMS_FILES_DELETE_FAIL";
export const UPDATE_CASTE_SUCCESS = "UPDATE_CASTE_SUCCESS";
export const UPDATE_CASTE_FAIL = "UPDATE_CASTE_FAIL";
export const UPDATE_GOTRA_SUCCESS = "UPDATE_GOTRA_SUCCESS";
export const UPDATE_GOTRA_FAIL = "UPDATE_GOTRA_FAIL";
export const DELETE_GOTRA_SUCCESS = "DELETE_GOTRA_SUCCESS";
export const DELETE_GOTRA_FAIL = "DELETE_GOTRA_FAIL";
