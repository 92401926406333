export const GET_ONLINE_USERS_SUCCESS = "GET_ONLINE_USERS_SUCCESS";
export const GET_ONLINE_USERS_FAIL = "GET_ONLINE_USERS_FAIL";
export const GET_UNDER_REVIEW_USERS_SUCCESS = "GET_UNDER_REVIEW_USERS_SUCCESS";
export const GET_UNDER_REVIEW_USERS_FAIL = "GET_UNDER_REVIEW_USERS_FAIL";
export const GET_PROFILE_VIEW_USERS_SUCCESS = "GET_PROFILE_VIEW_USERS_SUCCESS";
export const GET_PROFILE_VIEW_USERS_FAIL = "GET_PROFILE_VIEW_USERS_FAIL";
export const UPDATE_PROFILE_VIEW_USERS_SUCCESS =
  "UPDATE_PROFILE_VIEW_USERS_SUCCESS";
export const UPDATE_PROFILE_VIEW_USERS_FAIL = "UPDATE_PROFILE_VIEW_USERS_FAIL";
export const USER_VERIFY_SUCCESS = "USER_VERIFY_SUCCESS";
export const USER_VERIFY_FAIL = "USER_VERIFY_FAIL";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";
export const GET_TODAY_USERS_SUCCESS = "GET_TODAY_USERS_SUCCESS";
export const GET_TODAY_USERS_FAIL = "GET_TODAY_USERS_FAIL";
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
export const DELETE_USERS_FAIL = "DELETE_USERS_FAIL";
