import {
  BREADCRUMB_SPREADER,
  CATEGORY_SPREADER,
  SIDEBAR_LAYLOUT_DEFAULT,
  SIDEBAR_LAYLOUT_MINI,
  SEARCH_QUERY_ENTERED,
} from "./types";

const initialState = {
  layoutMode: "default",
  sidebarMini: false,
  layoutSkin: localStorage.getItem("layoutSkin") || "default",
  layoutSkinAuto: localStorage.getItem("layoutSkin") || "auto",
  sidebarSkin: "default",
  category: "",
  breadcrumb: { heading: "", subHeading: "" },
  query: "",
};

const Layout = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SWITCHER_SUPPORT_TRUE":
      return { ...state, switcherSupport: true };

    case "SWITCHER_SUPPORT_FALSE":
      return { ...state, switcherSupport: false };
    case CATEGORY_SPREADER:
      return { ...state, category: payload };
    case SIDEBAR_LAYLOUT_DEFAULT:
      return { ...state, sidebarMini: false };
    case SIDEBAR_LAYLOUT_MINI:
      return { ...state, sidebarMini: true };
    case BREADCRUMB_SPREADER:
      return { ...state, breadcrumb: payload };
    case SEARCH_QUERY_ENTERED:
      return { ...state, query: payload };
    default:
      return state;
  }
};

export default Layout;
