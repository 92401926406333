import {
  CMS_FILES_UPLOAD_SUCCESS,
  CMS_FILES_UPLOAD_FAIL,
  CMS_FILES_GET_FAIL,
  CMS_FILES_GET_SUCCESS,
  CMS_FILES_DELETE_SUCCESS,
  CMS_FILES_DELETE_FAIL,
  UPDATE_CASTE_SUCCESS,
  UPDATE_CASTE_FAIL,
  UPDATE_GOTRA_SUCCESS,
  UPDATE_GOTRA_FAIL,
  DELETE_GOTRA_SUCCESS,
  DELETE_GOTRA_FAIL,
} from "./types";
import axios from "../axios";

export const uploadCMSFiles = (data) => async (dispatch) => {
  try {
    const res = await axios.post("/cms/pics/new", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    dispatch({
      type: CMS_FILES_UPLOAD_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CMS_FILES_UPLOAD_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};
export const getCMSFiles = () => async (dispatch) => {
  try {
    const res = await axios.get("/cms/pics/get-all");

    dispatch({
      type: CMS_FILES_GET_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CMS_FILES_GET_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};
export const deleteCMSFiles = (link) => async (dispatch) => {
  try {
    const res = await axios.delete(`/cms/pics/${link}`);

    dispatch({
      type: CMS_FILES_DELETE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CMS_FILES_DELETE_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

//===================caste==================

export const updateCaste = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/cms/caste/new-caste", body);
    dispatch({
      type: UPDATE_CASTE_SUCCESS,
      payload: res.data.caste,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CASTE_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};
export const deleteCaste = (caste) => async (dispatch) => {
  try {
    const res = await axios.delete(`/cms/caste/${caste}`);
    dispatch({
      type: UPDATE_CASTE_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CASTE_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

//============== Gotra ================
export const updateGotra = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/cms/gotra/new-gotra", body);
    dispatch({
      type: UPDATE_GOTRA_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_GOTRA_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};
export const deleteGotra = (gotra) => async (dispatch) => {
  try {
    await axios.delete(`/cms/gotra/${gotra}`);
    dispatch({
      type: DELETE_GOTRA_SUCCESS,
      payload: gotra,
    });
  } catch (error) {
    dispatch({
      type: DELETE_GOTRA_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};
