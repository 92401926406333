import axios from "../axios";
import {
  GET_ONLINE_USERS_SUCCESS,
  GET_ONLINE_USERS_FAIL,
  GET_UNDER_REVIEW_USERS_SUCCESS,
  GET_UNDER_REVIEW_USERS_FAIL,
  GET_PROFILE_VIEW_USERS_SUCCESS,
  GET_PROFILE_VIEW_USERS_FAIL,
  USER_VERIFY_SUCCESS,
  USER_VERIFY_FAIL,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  UPDATE_PROFILE_VIEW_USERS_SUCCESS,
  UPDATE_PROFILE_VIEW_USERS_FAIL,
  GET_TODAY_USERS_SUCCESS,
  GET_TODAY_USERS_FAIL,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_FAIL,
} from "./types";

export const getOnlineUsers = () => async (dispatch) => {
  try {
    const res = await axios.get("/user/online-users");
    dispatch({
      type: GET_ONLINE_USERS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_ONLINE_USERS_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const getUnderReviewUsers = () => async (dispatch) => {
  try {
    const res = await axios.get("/user/under-review");
    dispatch({
      type: GET_UNDER_REVIEW_USERS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_UNDER_REVIEW_USERS_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const getUserInProfileView = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/user/user-in-profile", body);

    dispatch({
      type: GET_PROFILE_VIEW_USERS_SUCCESS,
      payload: res.data.user,
    });
  } catch (error) {
    dispatch({
      type: GET_PROFILE_VIEW_USERS_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

// above new code
// export const getUserInProfileView = (userId) => async (dispatch) => {
//   try {
//     const res = await axios.post(`/user/user-in-profile/${userId}`);

//     dispatch({
//       type: GET_PROFILE_VIEW_USERS_SUCCESS,
//       payload: res.data.user,
//     });
//   } catch (error) {
//     dispatch({
//       type: GET_PROFILE_VIEW_USERS_FAIL,
//       payload: error.response?.data.msg || error.message,
//     });
//   }
// };

export const userVerify = (data, body, userId) => async (dispatch) => {
  try {
    const res = await axios.put(`/user/verify/${userId}`, body);

    dispatch({
      type: USER_VERIFY_SUCCESS,
      payload: res.data.msg,
    });
    // let data = JSON.parse(localStorage.getItem("tempUserAdmin"));
    data = { ...data, underReview: false };
    // localStorage.setItem("tempUserAdmin", JSON.stringify(data));
    dispatch(getUserInProfileView(data));
  } catch (error) {
    dispatch({
      type: USER_VERIFY_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const userDataUpdate = (body, userId) => async (dispatch) => {
  try {
    const res = await axios.patch(`/user/by-admin/${userId}`, body);
    dispatch({
      type: UPDATE_PROFILE_VIEW_USERS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_VIEW_USERS_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const userSammelanUpdate = (body, userId) => async (dispatch) => {
  try {
    const res = await axios.patch(`/user/by-admin/sammelan/${userId}`, body);
    dispatch({
      type: UPDATE_PROFILE_VIEW_USERS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_VIEW_USERS_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const getAllUsers = () => async (dispatch) => {
  try {
    const res = await axios.get("/user/by-admin/all-users");
    dispatch({
      type: GET_ALL_USERS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    console.error("Error fetching all users:", error);
    const errorMessage =
      error.response?.data.msg || "An error occurred while fetching users.";
    dispatch({
      type: GET_ALL_USERS_FAIL,
      payload: errorMessage,
    });
  }
};


export const deleteUser = (data) => async (dispatch) => {
  try {
    console.log(data, "data");
    const res = await axios.delete(
      `/user/delete-user/${data.id}/${data.gender}/${data.underReview}`
    );
    dispatch({
      type: DELETE_USERS_SUCCESS,
      payload: data.id,
    });
    console.log(res);
  } catch (error) {
    dispatch({
      type: DELETE_USERS_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const getTodayUsers = () => async (dispatch) => {
  try {
    const res = await axios.get("/user/today-registered");
    dispatch({
      type: GET_TODAY_USERS_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_TODAY_USERS_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};
