import { Suspense, useEffect } from "react";
import "./App.css"
import { Routes, Route } from "react-router-dom";
import { privateRoutes } from "./routes/privateRoutes";
import Layout from "./layout";
import Auth from "./auth";
import LayoutProtection from "./routes/LayoutProtection";
import RoutesPropsSpreader from "./routes/RoutesPropsSpreader";
import publicRoutes from "./routes/publicRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, loadUser } from "./redux/actions";

function App() {
  const dispatch = useDispatch();
  const { currentUser, isAuthenticated } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (isAuthenticated && !currentUser) {
      dispatch(getCurrentUser());
    }
  }, [dispatch, currentUser, isAuthenticated]);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);
  return (
    <>
      <Suspense fallback={<></>}>
        <Routes>
          <Route element={<Auth />}>
            {publicRoutes.map((route, idx) => (
              <Route
                key={idx}
                element={<route.Component />}
                path={route.Path}
              />
            ))}
          </Route>

          <Route element={<LayoutProtection />}>
            <Route element={<Layout />}>
              {privateRoutes.map((route, idx) => (
                <Route
                  key={idx + 1}
                  element={
                    <RoutesPropsSpreader
                      category={route.Category}
                      breadcrumb={route.Breadcrumb}
                    />
                  }
                >
                  <Route
                    key={idx}
                    path={route.Path}
                    element={<route.Component />}
                  />
                </Route>
              ))}
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
