// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Inter/Inter-Thin.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Inter/Inter-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Inter/Inter-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Inter/Inter-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/Inter/Inter-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/Inter/Inter-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../fonts/Inter/Inter-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../fonts/Inter/Inter-Black.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "InterThin";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-weight: 100;
}
@font-face {
  font-family: "InterLight";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-weight: 300;
}
@font-face {
  font-family: "InterRegular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  font-weight: 400;
}
@font-face {
  font-family: "InterMedium";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
  font-weight: 500;
}
@font-face {
  font-family: "InterSemiBold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
  font-weight: 600;
}
@font-face {
  font-family: "InterBold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
  font-weight: 700;
}
@font-face {
  font-family: "InterExtraBold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
  font-weight: 800;
}
@font-face {
  font-family: "InterBlack";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_7___});
  font-weight: 900;
}
.app-default {
  background-color: var(--kt-app-light-sidebar-page-bg-color);
}
body {
  font-family: "InterRegular";
  font-size: 13px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/base.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,4CAAyC;EACzC,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,4CAA0C;EAC1C,gBAAgB;AAClB;AACA;EACE,2BAA2B;EAC3B,4CAA4C;EAC5C,gBAAgB;AAClB;AACA;EACE,0BAA0B;EAC1B,4CAA2C;EAC3C,gBAAgB;AAClB;AACA;EACE,4BAA4B;EAC5B,4CAA6C;EAC7C,gBAAgB;AAClB;AACA;EACE,wBAAwB;EACxB,4CAAyC;EACzC,gBAAgB;AAClB;AACA;EACE,6BAA6B;EAC7B,4CAA8C;EAC9C,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,4CAA0C;EAC1C,gBAAgB;AAClB;AACA;EACE,2DAA2D;AAC7D;AACA;EACE,2BAA2B;EAC3B,eAAe;EACf,gBAAgB;EAChB,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["@font-face {\n  font-family: \"InterThin\";\n  src: url(\"../fonts/Inter/Inter-Thin.ttf\");\n  font-weight: 100;\n}\n@font-face {\n  font-family: \"InterLight\";\n  src: url(\"../fonts/Inter/Inter-Light.ttf\");\n  font-weight: 300;\n}\n@font-face {\n  font-family: \"InterRegular\";\n  src: url(\"../fonts/Inter/Inter-Regular.ttf\");\n  font-weight: 400;\n}\n@font-face {\n  font-family: \"InterMedium\";\n  src: url(\"../fonts/Inter/Inter-Medium.ttf\");\n  font-weight: 500;\n}\n@font-face {\n  font-family: \"InterSemiBold\";\n  src: url(\"../fonts/Inter/Inter-SemiBold.ttf\");\n  font-weight: 600;\n}\n@font-face {\n  font-family: \"InterBold\";\n  src: url(\"../fonts/Inter/Inter-Bold.ttf\");\n  font-weight: 700;\n}\n@font-face {\n  font-family: \"InterExtraBold\";\n  src: url(\"../fonts/Inter/Inter-ExtraBold.ttf\");\n  font-weight: 800;\n}\n@font-face {\n  font-family: \"InterBlack\";\n  src: url(\"../fonts/Inter/Inter-Black.ttf\");\n  font-weight: 900;\n}\n.app-default {\n  background-color: var(--kt-app-light-sidebar-page-bg-color);\n}\nbody {\n  font-family: \"InterRegular\";\n  font-size: 13px;\n  font-weight: 400;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nbody {\n  display: flex;\n  flex-direction: column;\n}\n\na {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
