import axios from "../axios";
import {
  GET_STAFF_SUCCESS,
  GET_STAFF_FAIL,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAIL,
  NEW_STAFF_CREATED_SUCCESS,
  NEW_STAFF_CREATED_FAIL,
} from "./types";

export const getAllStaf = () => async (dispatch) => {
  try {
    const res = await axios.get("/admin/all-staff");

    dispatch({
      type: GET_STAFF_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_STAFF_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};
export const deleteStaffWithId = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/admin/${id}`);

    dispatch({
      type: DELETE_STAFF_SUCCESS,
      payload: { data: res.data, id },
    });
  } catch (error) {
    dispatch({
      type: DELETE_STAFF_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const addNewStaff = (body) => async (dispatch) => {
  try {
    const res = await axios.post(`/admin/new-staff`, body);

    dispatch({
      type: NEW_STAFF_CREATED_SUCCESS,
      payload: res.data.msg,
    });

    dispatch(getAllStaf());
  } catch (error) {
    dispatch({
      type: NEW_STAFF_CREATED_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};
