// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this CSS to your stylesheet or component's style */
.header-search {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
  }
  
  .search-input {
    width: 100%; /* Take full width */
    font-size: 16px; /* Adjust font size as needed */
    margin-top: 10px; /* Add spacing between elements */
  }
  
  /* For tablet screens */
  @media (max-width: 992px) {
    .header-search {
      width: 100%; /* Adjust the width as needed for tablets */
    }
  
    .search-input {
      font-size: 14px; /* Adjust the font size as needed for tablets */
    }
  }
  
  /* For mobile screens */
  @media (max-width: 576px) {
    .header-search {
      width: 100%; /* Adjust the width as needed for mobile devices */
    }
  
    .search-input {
      font-size: 12px; /* Adjust the font size as needed for mobile devices */
    }
    .search-mob{display: flex; 
    flex-direction: column;
    }

  }
  `, "",{"version":3,"sources":["webpack://./src/layout/partials/headerStyling.css"],"names":[],"mappings":"AAAA,yDAAyD;AACzD;IACI,aAAa;IACb,sBAAsB,EAAE,8BAA8B;EACxD;;EAEA;IACE,WAAW,EAAE,oBAAoB;IACjC,eAAe,EAAE,+BAA+B;IAChD,gBAAgB,EAAE,iCAAiC;EACrD;;EAEA,uBAAuB;EACvB;IACE;MACE,WAAW,EAAE,2CAA2C;IAC1D;;IAEA;MACE,eAAe,EAAE,+CAA+C;IAClE;EACF;;EAEA,uBAAuB;EACvB;IACE;MACE,WAAW,EAAE,kDAAkD;IACjE;;IAEA;MACE,eAAe,EAAE,sDAAsD;IACzE;IACA,YAAY,aAAa;IACzB,sBAAsB;IACtB;;EAEF","sourcesContent":["/* Add this CSS to your stylesheet or component's style */\n.header-search {\n    display: flex;\n    flex-direction: column; /* Stack elements vertically */\n  }\n  \n  .search-input {\n    width: 100%; /* Take full width */\n    font-size: 16px; /* Adjust font size as needed */\n    margin-top: 10px; /* Add spacing between elements */\n  }\n  \n  /* For tablet screens */\n  @media (max-width: 992px) {\n    .header-search {\n      width: 100%; /* Adjust the width as needed for tablets */\n    }\n  \n    .search-input {\n      font-size: 14px; /* Adjust the font size as needed for tablets */\n    }\n  }\n  \n  /* For mobile screens */\n  @media (max-width: 576px) {\n    .header-search {\n      width: 100%; /* Adjust the width as needed for mobile devices */\n    }\n  \n    .search-input {\n      font-size: 12px; /* Adjust the font size as needed for mobile devices */\n    }\n    .search-mob{display: flex; \n    flex-direction: column;\n    }\n\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
