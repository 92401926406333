import {
  CATEGORY_SPREADER,
  BREADCRUMB_SPREADER,
  SIDEBAR_LAYLOUT_DEFAULT,
  SIDEBAR_LAYLOUT_MINI,
  SEARCH_QUERY_ENTERED,
} from "./types";

export const categorySpreader = (category) => (dispatch) => {
  try {
    dispatch({
      type: CATEGORY_SPREADER,
      payload: category,
    });
  } catch (error) {
    console.log(error.message);
  }
};
export const breadcrumbSpreader = (breadcrumb) => (dispatch) => {
  try {
    dispatch({
      type: BREADCRUMB_SPREADER,
      payload: breadcrumb,
    });
  } catch (error) {
    console.log(error.message);
  }
};

export const setSidebarLayout = (mini) => (dispatch) => {
  try {
    const sidebarWidth = document.getElementById("app-sidebar-width");
    if (!sidebarWidth) return;
    if (mini) {
      if (window.innerWidth <= "991px") {
        sidebarWidth.style.width = "250px";
      } else {
        sidebarWidth.style.width = "300px";
      }
      dispatch({
        type: SIDEBAR_LAYLOUT_DEFAULT,
      });
    } else {
      sidebarWidth.style.width = "82px";
      dispatch({
        type: SIDEBAR_LAYLOUT_MINI,
      });
    }
  } catch (error) {}
};

export const setThroughSearch = (query) => (dispatch) => {
  try {
    dispatch({
      type: SEARCH_QUERY_ENTERED,
      payload: query,
    });
  } catch (error) {}
};
