import { Outlet } from "react-router-dom";

function Auth() {
  return (
    <div className="container-fluid">
      <main className="py-5">
        <Outlet />
      </main>
    </div>
  );
}

export default Auth;
