import {
  GET_STAFF_SUCCESS,
  GET_STAFF_FAIL,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAIL,
  NEW_STAFF_CREATED_SUCCESS,
  NEW_STAFF_CREATED_FAIL,
} from "./types";

const initialState = {
  allStaff: [],
  error: "",
  success: "",
};

const Staff = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_STAFF_SUCCESS:
      return { ...state, allStaff: payload, success: "", error: "" };
    case GET_STAFF_FAIL:
      return { ...state, allStaff: [], success: "", error: payload };
    case NEW_STAFF_CREATED_SUCCESS:
      return { ...state, success: payload, error: "" };
    case NEW_STAFF_CREATED_FAIL:
      return { ...state, success: "", error: payload };
    case DELETE_STAFF_SUCCESS:
      return {
        ...state,
        success: payload.data.msg,
        error: "",
        allStaff: state.allStaff.filter((staff) => staff._id !== payload.id),
      };
    case DELETE_STAFF_FAIL:
      return { ...state, success: "", error: payload };
    default:
      return state;
  }
};

export default Staff;
