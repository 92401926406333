export const useMenuToggleOnClick = (el = null) => {
  function menuToggle(menuId) {
    el = document.getElementById(menuId);
    if (!el) return;
    return el.classList.contains("show")
      ? el.classList.remove("show")
      : el.classList.add("show");
  }
  return menuToggle;
};
export const useSubMenuToggleOnClick = (el = null) => {
  function subMenuToggle(menuId) {
    el = document.getElementById(menuId);
    if (!el) return;
    if (el.classList.contains("show")) {
      el.classList.remove("show");
      return "d-none overflow-hidden";
    } else if (el.classList.contains("d-none")) {
      el.classList.remove("d-none", "overflow-hidden");
      return "show";
    }
  }
  return subMenuToggle;
};
