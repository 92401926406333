import {
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  USER_LOADED,
  AUTH_ERROR,
} from "./types";
import base64 from "base-64";

import axios from "axios";
import axiosAuth from "../axios";

const BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL_SERVER;

export const loadUser = () => async (dispatch) => {
  try {
    if (localStorage.token) {
      dispatch({
        type: USER_LOADED,
      });
    }
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const getCurrentUser = () => async (dispatch) => {
  try {
    const res = await axiosAuth.get(`/auth/current-user`);
    dispatch({
      type: GET_CURRENT_USER_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_CURRENT_USER_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const login =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      let encoded = email + ":" + password;
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Basic ${base64.encode(encoded)}`,
        },
      };
      const res = await axios.get(BASE_URL + "/admin/auth-token", config);

      dispatch({
        type: ADMIN_LOGIN_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_LOGIN_FAIL,
        payload: error.response?.data.msg || error.message,
      });
    }
  };

export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: LOGOUT_FAIL,
    });
  }
};
