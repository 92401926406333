import { Outlet } from "react-router-dom";
import "../assets/bootstrap/css/bootstrap.min.css";
import "../assets/styles/light-mode.css";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function Layout() {
  const { sidebarMini } = useSelector((state) => state.Layout);
  // useEffect(() => {
  //   const appWrapper = document.getElementById("app-wrapper");
  //   if(window.innerWidth >= 992) {

  //     if (sidebarMini) {
  //       appWrapper.style.marginLeft = 90 + "px";
  //     } else {
  //       if (window.innerWidth <= 991) {
  //         appWrapper.style.marginLeft = 250 + "px";
  //       } else {
  //         appWrapper.style.marginLeft = 300 + "px";
  //       }
  //     }
  //   } else {
  //     appWrapper.style.display = 'none'
  //   }
  // }, [sidebarMini]);

  return (
    <>
      <div className="d-flex flex-column flex-root app-root">
        <div className="app-page  flex-column flex-column-fluid ">
          <div className="app-header">
            <div className="app-container container-fluid d-flex align-items-stretch justify-content-between ">
              <Header />
            </div>
          </div>
          <div
            className="app-wrapper  flex-column flex-row-fluid "
            data-app-wrapper-mini={sidebarMini}
            data-app-wrapper-screen={window.innerWidth <= 991}
            id="app-wrapper"
          >
            <Sidebar />
            <main className="app-main flex-column flex-row-fluid">
              <div className="d-flex flex-column flex-column-fluid">
                <div className="app-content  flex-column-fluid ">
                  <div className="app-container  container-fluid ">
                    <Outlet />
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
