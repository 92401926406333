export const GET_SAMMELAN_SUCCESS = "GET_SAMMELAN_SUCCESS";
export const GET_SAMMELAN_FAIL = "GET_SAMMELAN_FAIL";
export const ADD_NEW_SAMMELAN_SUCCESS = "ADD_NEW_SAMMELAN_SUCCESS";
export const ADD_NEW_SAMMELAN_FAIL = "ADD_NEW_SAMMELAN_FAIL";
export const SAMMELAN_DELETE_SUCCESS = "SAMMELAN_DELETE_SUCCESS";
export const SAMMELAN_DELETE_FAILED = "SAMMELAN_DELETE_FAILED";
export const SAMMELAN_UPDATED_SUCCESS = "SAMMELAN_UPDATED_SUCCESS";
export const SAMMELAN_UPDATED_FAILED = "SAMMELAN_UPDATED_FAILED";
export const SET_SELECTED_SAMMELAN = "SET_SELECTED_SAMMELAN";
export const GET_SELECTED_SAMMELAN_SUCCESS = "GET_SELECTED_SAMMELAN_SUCCESS";
export const GET_SELECTED_SAMMELAN_FAILURE = "GET_SELECTED_SAMMELAN_FAILURE";
