import "../assets/styles/Sidebar.css";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MenuArrow from "./partials/MenuArrow";
import { useSelector, useDispatch } from "react-redux";
import { setSidebarLayout } from "../redux/actions";
import {
  useMenuToggleOnClick,
  useSubMenuToggleOnClick,
} from "../assets/js/sidebar";

import logo from "../assets/images/olakh-logo.png";
import { FaTimes, FaBars } from "react-icons/fa";

function Sidebar({ }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  const { category, sidebarMini } = useSelector((state) => state.Layout);

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const menuToggle = useMenuToggleOnClick();
  const subMenuToggle = useSubMenuToggleOnClick();

  const sidebarToggle = () => {
    dispatch(setSidebarLayout(sidebarMini));
  };

  useEffect(() => {
    let menuTitles = document.querySelectorAll(".menu-title");
    menuTitles = [...menuTitles];
    let menuLinks = document.querySelectorAll(".menu-link");

    if (sidebarMini) {
      menuTitles.forEach((menu) => {
        menu.classList.remove("d-block");
        menu.classList.add("d-none");
      });
      // setTimeout(() => {
      menuLinks.forEach((menu) => (menu.style.justifyContent = "end"));
      // }, 160);
    } else {
      menuTitles.forEach((menu) => {
        menu.classList.remove("d-none");
        menu.classList.add("d-block");
      });
      // setTimeout(() => {
      menuLinks.forEach((menu) => (menu.style.justifyContent = "start"));
      // }, 160);
    }
  }, [sidebarMini]);

  // const onHoverMini = () => {
  //   if (!sidebarMini) return;
  //   setTimeout(() => {
  //     const sidebarWidth = document.getElementById("app-sidebar-width");
  //     sidebarWidth.style.position = "absolute";
  //     dispatch(setSidebarLayout(false));
  //   }, 500);
  // };

  // const onLeaveMini = () => {
  //   if (!sidebarMini) return;
  //   setTimeout(() => {
  //     const sidebarWidth = document.getElementById("app-sidebar-width");

  //     sidebarWidth.style.position = "fixed";
  //     dispatch(setSidebarLayout(true));  
  //   }, 200);
  // };

  return (
    <>
      <div className={`app-sidebar ${isSidebarOpen ? "open" : ""}`}    >
        <div className="mobile-hamburger" onClick={toggleSidebar} style={{ backgroundColor: '#333', color: 'white' }}>
          {isSidebarOpen ? <FaTimes /> : <FaBars />}
        </div>

        <div className="app-sidebar flex-column px-2" id="app-sidebar-width" style={{
          left: isSidebarOpen ? '0px' : '-250px'
        }} >
          <div className="pt-3 app-sidebar-logo d-none d-lg-flex justify-content-between w-100 px-2">
            <Link to="/">
              <div className="pt-3 app-sidebar-logo d-none d-lg-flex justify-content-between w-100 px-2">
                {sidebarMini ? (
                  <span className="text-primary fs-5">Olakh</span>
                ) : (
                  <img
                    src={logo}
                    alt="Your Logo"
                    style={{ width: "100%", height: "80%", background: "red", borderRadius: "2rem", padding: "0.3rem" }}
                  />
                )}
              </div>
            </Link>

            <div>
              <button
                onClick={sidebarToggle}
                className={`btn btn-icon btn-icon-custom-color btn-chevron-cover btn-active-color-primary px-0 `}
                id="sidebar-btn-toggle"
              >
                <i
                  className={`bi bi-caret-right-fill fs-4 chevron-sidebar ${sidebarMini ? "mini" : "maxi"
                    }`}
                ></i>
              </button>
            </div>
          </div>

          <div className="separator d-none d-lg-block"></div>

          <div className="app-sidebar-menu  app-sidebar-menu-arrow   hover-scroll-overlay-y my-5 my-lg-1">
            <div
              className="menu 
            menu-column 
            menu-sub-indention 
            menu-active-bg 
            fw-normal"
            >
              {/* Dashboard */}
              <div className="menu-item" onClick={toggleSidebar}>
                <Link
                  className={`menu-link ${category === "dashboard" ? "active" : ""
                    }`}
                  to="/dashboard"
                >
                  <span className="menu-icon">
                    <span className="svg-icon svg-icon-5">
                      <i
                        className={`bi bi-bar-chart-line-fill ${category === "dashboard" ? "text-primary" : ""
                          } ${sidebarMini
                            ? "fs-5 sidebar-text-trans"
                            : "sidebar-text-trans"
                          }`}
                      ></i>
                    </span>
                  </span>
                  <span className="menu-title">Dashboard</span>
                </Link>
              </div>


              {/* Sammelans */}

              <div className="menu-item" onClick={toggleSidebar}>
                <Link
                  className={`menu-link ${category === "sammelan" ? "active text-danger" : ""
                    }`}
                  to="/sammelan"
                >
                  <span className="menu-icon">
                    <span className="svg-icon svg-icon-5">
                      <i
                        className={`bi bi-person-hearts ${category === "sammelan" ? "text-danger" : ""
                          } ${sidebarMini
                            ? "fs-5 sidebar-text-trans"
                            : "sidebar-text-trans"
                          }`}
                      ></i>
                    </span>
                  </span>
                  <span className="menu-title">Sammelan</span>
                </Link>
              </div>

              {/* Users Info */}
              <div
                id="user-info-accordion"
                className={`menu-item ${pathname.includes("user") ? "show" : ""}`}
              >
                <span
                  className={`menu-link`}
                  data-menu-toggle="user-info"
                  id="user-info-link"
                  onClick={() => menuToggle("user-info-accordion")}
                >
                  <span className="menu-icon">
                    <span className="svg-icon svg-icon-5">
                      <i
                        className={`bi bi-people-fill ${category === "users-info" ? "text-warning" : ""
                          } ${sidebarMini
                            ? "fs-5 sidebar-text-trans"
                            : "sidebar-text-trans"
                          }`}
                      ></i>
                    </span>
                  </span>
                  <span
                    className={`menu-title  ${category === "users-info" ? "active text-warning" : ""
                      }`}
                  >
                    Users Info
                  </span>
                  <span className="menu-arrow"></span>
                </span>
                <div
                  id="user-info-submenu"
                  className={`menu-sub menu-sub-accordion ${subMenuToggle(
                    "user-info-submenu"
                  )}`}
                >
                  <div className="menu-item" onClick={toggleSidebar} >
                    <Link
                      className={`menu-link ${pathname === "/user/todays-users" ? "active" : ""
                        }`}
                      to="user/todays-users"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Today's Users</span>
                    </Link>
                  </div>

                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/user/online-users" ? "active" : ""
                        }`}
                      to="user/online-users"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Online Users</span>
                    </Link>
                  </div>

                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/user/under-review" ? "active" : ""
                        }`}
                      to="user/under-review"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Under Review</span>
                    </Link>
                  </div>

                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/user/all-users" ? "active" : ""
                        }`}
                      to="user/all-users"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">All Users</span>
                    </Link>
                  </div>
                </div>
              </div>

              {/* Packages */}
              <div
                id="package-accordion"
                className={`menu-item ${pathname.includes("package") ? "show" : ""
                  }`}
              >
                <span
                  className={`menu-link`}
                  data-menu-toggle="package"
                  id="package-link"
                  onClick={() => menuToggle("package-accordion")}
                >
                  <span className="menu-icon">
                    <span className="svg-icon svg-icon-5">
                      <i
                        className={`bi bi-box2-heart-fill ${category === "packages" ? "text-info" : ""
                          } ${sidebarMini
                            ? "fs-5 sidebar-text-trans"
                            : "sidebar-text-trans"
                          }`}
                      ></i>
                    </span>
                  </span>
                  <span
                    className={`menu-title  ${category === "packages" ? "active text-info" : ""
                      }`}
                  >
                    Packages
                  </span>
                  <span className="menu-arrow"></span>
                </span>
                <div
                  id="package-submenu"
                  className={`menu-sub menu-sub-accordion ${subMenuToggle(
                    "package-submenu"
                  )}`}
                >
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/package/new-package" ? "active" : ""
                        }`}
                      to="package/new-package"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">New Package</span>
                    </Link>
                  </div>
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/package/all-packages" ? "active" : ""
                        }`}
                      to="package/all-packages"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">All Packages</span>
                    </Link>
                  </div>
                </div>
              </div>

              {/* Payments Menu */}
              {/* <div
              id="payments-accordion"
              className={`menu-item  menu-accordion ${pathname.includes("payments") ? "show" : ""
                }`}
            >
              <span
                className="menu-link"
                data-menu-toggle="payments"
                id="payments-link"
                onClick={() => menuToggle("payments-accordion")}
              >
                <span className="menu-icon">
                  <span className="svg-icon svg-icon-5">
                    <MenuArrow />
                  </span>
                </span>
                <span className="menu-title">Payments</span>
                <span className="menu-arrow"></span>
              </span>
              <div
                id="payments-submenu"
                className={`menu-sub menu-sub-accordion ${subMenuToggle(
                  "payments-submenu"
                )}`}
              >
                <div className="menu-item">
                  <Link
                    className={`menu-link ${pathname === "/payments/successful" ? "active" : ""
                      }`}
                    to="payments/successful"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Successful</span>
                  </Link>
                </div>

                <div className="menu-item">
                  <Link
                    className={`menu-link ${pathname === "/payments/pending" ? "active" : ""
                      }`}
                    to="payments/pending"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Pending</span>
                  </Link>
                </div>

                <div className="menu-item">
                  <Link
                    className={`menu-link ${pathname === "/payments/rejected" ? "active" : ""
                      }`}
                    to="payments/rejected"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Rejected</span>
                  </Link>
                </div>
                <div className="menu-item">
                  <Link
                    className={`menu-link ${pathname === "/payments/total-payments" ? "active" : ""
                      }`}
                    to="payments/total-payments"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Total Payments</span>
                  </Link>
                </div>
              </div>
            </div> */}

              {/* Website CMS */}
              <div id="websiteCms-accordion" className={`menu-item`}>

                <span
                  className={`menu-link ${category === "websiteCms" ? "active text-info" : ""
                    }`}
                  data-menu-toggle="websiteCms"
                  id="websiteCms-link"
                  onClick={() => menuToggle("websiteCms-accordion")}
                >
                  <span className="menu-icon">
                    <span className="svg-icon svg-icon-5">
                      <i
                        className={`bi bi-box2-heart-fill ${category === "websiteCms" ? "text-info" : ""
                          } ${sidebarMini
                            ? "fs-5 sidebar-text-trans"
                            : "sidebar-text-trans"
                          }`}
                      ></i>
                    </span>
                  </span>

                  {/* User Profile Activity */}
                  {/* <div
                            id="user-profile-activity-accordion"
                            className={`menu-item ${pathname.includes("user-profile-activity") ? "show" : ""
                              }`}
                          >
                            <span
                              className={`menu-link`}
                              data-menu-toggle="user-profile-activity"
                              id="user-profile-activity-link"
                              onClick={() => menuToggle("user-profile-activity-accordion")}
                            >
                              <span className="menu-icon">
                                <span className="svg-icon svg-icon-5">
                                  <i
                                    className={`bi bi-box2-heart-fill ${category === "user-profile-activity" ? "text-info" : ""
                                      } ${sidebarMini
                                        ? "fs-5 sidebar-text-trans"
                                        : "sidebar-text-trans"
                                      }`}
                                  ></i>
                                </span>
                              </span>
                              <span
                                className={`menu-title  ${category === "user-profile-activity" ? "active text-info" : ""
                                  }`}
                              >
                                User Profile Activity
                              </span>
                              <span className="menu-arrow"></span>
                            </span>
                            <div
                              id="activity-log-submenu"
                              className={`menu-sub menu-sub-accordion ${subMenuToggle(
                                "activity-log"
                              )}`}
                            >
                              <div className="menu-item">
                                <Link
                                  className={`menu-link ${pathname === "/user-profile-activity/ActivityLog" ? "active" : ""
                                    }`}
                                  to="user-profile-activity/ActivityLog"
                                >
                                  <span className="menu-bullet">
                                    <span className="bullet bullet-dot"></span>
                                  </span>
                                  <span className="menu-title">Activity Log</span>
                                </Link>
                              </div>
                              <div className="menu-item">
                                <Link
                                  className={`menu-link ${pathname === "/user-profile-activity/DeletionRequest" ? "active" : ""
                                    }`}
                                  to="user-profile-activity/DeletionRequest"
                                >
                                  <span className="menu-bullet">
                                    <span className="bullet bullet-dot"></span>
                                  </span>
                                  <span className="menu-title">Deletion Request</span>
                                </Link>
                              </div>
                            </div>
                          </div> */}
                  <span className="menu-title">Website CMS</span>
                  <span className="menu-arrow"></span>
                </span>
                <div
                  id="websiteCms-submenu"
                  className={`menu-sub menu-sub-accordion ${subMenuToggle(
                    "websiteCms-submenu"
                  )}`}
                >
                  {/* <div className="menu-item">
                  <Link
                    className={`menu-link ${pathname === "/websiteCms/SliderImages" ? "active" : ""
                      }`}
                    to="/websiteCms/SliderImages"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Slider Images</span>
                  </Link>
                </div> */}
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/SuccessStories" ? "active" : ""
                        }`}
                      to="/websiteCms/SuccessStories"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Success Stories</span>
                    </Link>
                  </div>
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/DownloadMobile" ? "active" : ""
                        }`}
                      to="/websiteCms/DownloadMobile"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Download Mobile</span>
                    </Link>
                  </div>


                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/FactsAndFigures" ? "active" : ""
                        }`}
                      to="/websiteCms/FactsAndFigures"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Facts And Figures</span>
                    </Link>
                  </div>


                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/EventPopup" ? "active" : ""
                        }`}
                      to="/websiteCms/EventPopup"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Event Popup</span>
                    </Link>
                  </div>
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/PrivacyPolicy" ? "active" : ""
                        }`}
                      to="/websiteCms/PrivacyPolicy"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Privacy Policy</span>
                    </Link>
                  </div>
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/TermsAndCondition" ? "active" : ""
                        }`}
                      to="/websiteCms/TermsAndCondition"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Terms And Conditions</span>
                    </Link>
                  </div>

                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/Refunds" ? "active" : ""
                        }`}
                      to="/websiteCms/Refunds"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Refunds</span>
                    </Link>
                  </div>
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/Disclaimer" ? "active" : ""
                        }`}
                      to="/websiteCms/Disclaimer"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Disclaimer</span>
                    </Link>
                  </div>
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/ReportAndMisuse" ? "active" : ""
                        }`}
                      to="/websiteCms/ReportAndMisuse"
                    >
                      <span className="menu-bullet" onClick={toggleSidebar}>
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Report And Misuse</span>
                    </Link>
                  </div>
                  <div className="menu-item" onClick={toggleSidebar} >
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/Contact" ? "active" : ""
                        }`}
                      to="/websiteCms/Contact"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Contact</span>
                    </Link>
                  </div>
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/About" ? "active" : ""
                        }`}
                      to="/websiteCms/About"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">About</span>
                    </Link>
                  </div>
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/BankDetails" ? "active" : ""
                        }`}
                      to="/websiteCms/BankDetails"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Bank Details</span>
                    </Link>
                  </div>
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/Faq" ? "active" : ""
                        }`}
                      to="/websiteCms/Faq"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Faq</span>
                    </Link>
                  </div>
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/Event" ? "active" : ""
                        }`}
                      to="/websiteCms/Event"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Event</span>
                    </Link>
                  </div>
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/SocialLinks" ? "active" : ""
                        }`}
                      to="/websiteCms/SocialLinks"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Social Links</span>
                    </Link>
                  </div>
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/Testimonial" ? "active" : ""
                        }`}
                      to="/websiteCms/Testimonial"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Testimonial</span>
                    </Link>
                  </div>

                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/Team" ? "active" : ""
                        }`}
                      to="/websiteCms/Team"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Team</span>
                    </Link>
                  </div>


                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/websiteCms/SuccessStoryHome" ? "active" : ""
                        }`}
                      to="/websiteCms/SuccessStoryHome"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Success Story Home Images</span>
                    </Link>
                  </div>


                </div>

              </div>

              {/* Member Data Approval */}
              <div
                id="member-data-approval-accordion"
                className={`menu-item ${pathname.includes("user") ? "show" : ""}`}
              >
                <span
                  className={`menu-link`}
                  data-menu-toggle="member-data-approval"
                  id="member-data-approval-link"
                  onClick={() => menuToggle("member-data-approval-accordion")}
                >
                  <span className="menu-icon">
                    <span className="svg-icon svg-icon-5">
                      <i
                        className={`bi bi-box2-heart-fill ${category === "member-data-approval" ? "text-info" : ""
                          } ${sidebarMini
                            ? "fs-5 sidebar-text-trans"
                            : "sidebar-text-trans"
                          }`}
                      ></i>
                    </span>
                  </span>
                  <span
                    className={`menu-title  ${category === "member-data-approval"
                      ? "active text-info"
                      : ""
                      }`}
                  >
                    Member Data Approval
                  </span>
                  <span className="menu-arrow"></span>
                </span>

                {/* User Profile Photo Approval */}
                {/* <div
                id="UserProfilePhotoApproval"
                className={`menu-sub menu-sub-accordion ${subMenuToggle(
                  "UserProfilePhotoApproval"
                )} ${pathname.includes("UserProfilePhotoApproval") ? "show" : ""
                  }`}
              >
                <div className="menu-item">
                  <Link
                    className={`menu-link ${pathname ===
                      "/member-data-approval/UserProfilePhotoApproval"
                      ? "active"
                      : ""
                      }`}
                    to="/member-data-approval/UserProfilePhotoApproval"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">UserProfilePhotoApproval</span>
                  </Link>
                </div>
              </div> */}

                {/* User Gallery Approval */}
                <div
                  id="UserGalleryApproval"
                  className={`menu-sub menu-sub-accordion ${subMenuToggle(
                    "UserGalleryApproval"
                  )} ${pathname.includes("UserGalleryApproval") ? "show" : ""}`}
                >
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/member-data-approval/UserGalleryApproval"
                        ? "active"
                        : ""
                        }`}
                      to="/member-data-approval/UserGalleryApproval"
                    >
                      <span className="menu-bullet" onClick={toggleSidebar} >
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">User Gallery Approval</span>
                    </Link>
                  </div>
                </div>

                {/* User All Data Approval */}
                {/* <div
                id="UserAllDataApproval"
                className={`menu-sub menu-sub-accordion ${subMenuToggle(
                  "UserAllDataApproval"
                )} ${pathname.includes("UserAllDataApproval") ? "show" : ""}`}
              >
                <div className="menu-item">
                  <Link
                    className={`menu-link ${pathname === "/member-data-approval/UserAllDataApproval"
                      ? "active"
                      : ""
                      }`}
                    to="/member-data-approval/UserAllDataApproval"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">UserAllDataApproval</span>
                  </Link>
                </div>
              </div> */}
              </div>

              {/* User Attribute */}
              <div
                id="user-attribute-accordion"
                className={`menu-item ${pathname.includes("user-attribute") ? "show" : ""
                  }`}
              >
                <span
                  className={`menu-link`}
                  data-menu-toggle="user-attribute"
                  id="user-attribute-link"
                  onClick={() => menuToggle("user-attribute-accordion")}
                >
                  <span className="menu-icon">
                    <span className="svg-icon svg-icon-5">
                      <i
                        className={`bi bi-box2-heart-fill ${category === "user-attribute" ? "text-info" : ""
                          } ${sidebarMini
                            ? "fs-5 sidebar-text-trans"
                            : "sidebar-text-trans"
                          }`}
                      ></i>
                    </span>
                  </span>
                  <span
                    className={`menu-title  ${category === "user-attribute" ? "active text-info" : ""
                      }`}
                  >
                    User Attribute
                  </span>
                  <span className="menu-arrow"></span>
                </span>
                <div
                  id="user-attribute"
                  className={`menu-sub menu-sub-accordion ${subMenuToggle(
                    "user-attribute"
                  )}`}
                >
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/user-attribute/caste" ? "active" : ""
                        }`}
                      to="user-attribute/caste"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Caste</span>
                    </Link>
                  </div>
                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/user-attribute/gotra" ? "active" : ""
                        }`}
                      to="user-attribute/gotra"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Gotra</span>
                    </Link>
                  </div>
                </div>
              </div>

              {/* All Reports */}
              <div
                id="all-reports-accordion"
                className={`menu-item ${pathname.includes("all-reports") ? "show" : ""}`}
              >
                <span
                  className={`menu-link`}
                  data-menu-toggle="all-reports"
                  id="all-reports-link"
                  onClick={() => menuToggle("all-reports-accordion")}
                >
                  <span className="menu-icon">
                    <span className="svg-icon svg-icon-5">
                      <i
                        className={`bi bi-file-earmark-bar-graph-fill ${category === "all-reports" ? "text-info" : ""} ${sidebarMini ? "fs-5 sidebar-text-trans" : "sidebar-text-trans"}`}
                      ></i>
                    </span>
                  </span>
                  <span className={`menu-title ${category === "all-reports" ? "active text-info" : ""}`}>
                    All Reports
                  </span>
                  <span className="menu-arrow"></span>
                </span>
                <div
                  id="all-reports-submenu"
                  className={`menu-sub menu-sub-accordion ${subMenuToggle("all-reports")}`}
                >

                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/all-reports/top-profile-views" ? "active" : ""}`}
                      to="/all-reports/top-profile-views"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Top Profile Views</span>
                    </Link>
                  </div>

                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/all-reports/contact-messages" ? "active" : ""}`}
                      to="/all-reports/contact-messages"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Contact Messages</span>
                    </Link>
                  </div>

                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/all-reports/user-session" ? "active" : ""}`}
                      to="/all-reports/user-session"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">User Session</span>
                    </Link>
                  </div>

                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/all-reports/user-activity" ? "active" : ""}`}
                      to="/all-reports/user-activity"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">User Activity</span>
                    </Link>
                  </div>

                  <div className="menu-item" onClick={toggleSidebar}>
                    <Link
                      className={`menu-link ${pathname === "/all-reports/User-data-log" ? "active" : ""}`}
                      to="/all-reports/User-data-log"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">User Data Log</span>
                    </Link>
                  </div>

                  {/* <div className="menu-item">
                  <Link
                    className={`menu-link ${pathname === "/all-reports/deleted-profiles" ? "active" : ""}`}
                    to="/all-reports/deleted-profiles"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Deleted Profiles</span>
                  </Link>
                </div> */}

                  {/* <div className="menu-item">
                  <Link
                    className={`menu-link ${pathname === "/all-reports/id-proof-report" ? "active" : ""}`}
                    to="/all-reports/id-proof-report"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">ID Proof Report</span>
                  </Link>
                </div> */}

                  {/* <div className="menu-item">
                  <Link
                    className={`menu-link ${pathname === "/all-reports/ban-members" ? "active" : ""}`}
                    to="/all-reports/ban-members"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Ban Members</span>
                  </Link>
                </div> */}

                  {/* <div className="menu-item">
                  <Link
                    className={`menu-link ${pathname === "/all-reports/label-print" ? "active" : ""}`}
                    to="/all-reports/label-print"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Label Print</span>
                  </Link>
                </div> */}

                  {/* <div className="menu-item">
                  <Link
                    className={`menu-link ${pathname === "/all-reports/sales-report" ? "active" : ""}`}
                    to="/all-reports/sales-report"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Sales Report</span>
                  </Link>
                </div> */}

                  {/* <div className="menu-item">
                  <Link
                    className={`menu-link ${pathname === "/all-reports/search" ? "active" : ""}`}
                    to="/all-reports/search"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Search</span>
                  </Link>
                </div> */}

                  {/* <div className="menu-item">
                  <Link
                    className={`menu-link ${pathname === "/all-reports/advance-search" ? "active" : ""}`}
                    to="/all-reports/advance-search"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Advance Search</span>
                  </Link>
                </div> */}

                </div>
              </div>

              {/* Manage Staff */}
              <div className="menu-item" onClick={toggleSidebar}>
                <Link
                  className={`menu-link ${category === "manage-staff" ? "active text-success" : ""}`}
                  to="/manage-staff"
                >
                  <span className="menu-icon">
                    <span className="svg-icon svg-icon-5">
                      <i
                        className={`bi bi-people ${category === "manage-staff" ? "text-success" : ""} ${sidebarMini ? "fs-5 sidebar-text-trans" : "sidebar-text-trans"
                          }`}
                      ></i>
                    </span>
                  </span>
                  <span className="menu-title">Manage Staff</span>
                </Link>
              </div>

              <div className="menu-item" onClick={toggleSidebar}>
            <Link
              className={`menu-link ${category === "sammelan" ? "active text-danger" : ""
                }`}
              to="/deleteSammelanUsers"
            >
              <span className="menu-icon">
                <span className="svg-icon svg-icon-5">
                  <i
                    className={`bi bi-person-hearts ${category === "sammelan" ? "text-danger" : ""
                      } ${sidebarMini
                        ? "fs-5 sidebar-text-trans"
                        : "sidebar-text-trans"
                      }`}
                  ></i>
                </span>
              </span>
              <span className="menu-title">Delete Sammelan Users</span>
            </Link>
          </div>


            </div>
          </div>



          
        </div>

        {isSidebarOpen && (
          <div className="close-sidebar" onClick={toggleSidebar}>
            Close Sidebar
          </div>
        )}
      </div>


    </>
  );
}

export default Sidebar;
