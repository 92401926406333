import React from "react";

function MenuArror() {
  return (
    <>
      {" "}
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z"
          fill="currentColor"
        ></path>
        <path
          opacity="0.3"
          d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z"
          fill="currentColor"
        ></path>
      </svg>
    </>
  );
}

export default MenuArror;
