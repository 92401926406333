import { useSelector } from "react-redux";

function Breadcrumb() {
  const { breadcrumb } = useSelector((state) => state.Layout);
  return (
    <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-lg-0">
      <h1 className="page-heading d-flex text-dark fw-bold fs-1-15 flex-column justify-content-center my-0">
        {breadcrumb.heading}

        <span className="page-desc text-gray-400 fs-8 fw-semibold pt-1">
          {breadcrumb.subHeading}
        </span>
      </h1>
    </div>
  );
}

export default Breadcrumb;
