import axios from "../axios";
import {
  GET_SAMMELAN_SUCCESS,
  GET_SAMMELAN_FAIL,
  ADD_NEW_SAMMELAN_SUCCESS,
  ADD_NEW_SAMMELAN_FAIL,
  SAMMELAN_DELETE_SUCCESS,
  SAMMELAN_DELETE_FAILED,
  SAMMELAN_UPDATED_SUCCESS,
  SAMMELAN_UPDATED_FAILED,
  SET_SELECTED_SAMMELAN,
  GET_SELECTED_SAMMELAN_SUCCESS,
  GET_SELECTED_SAMMELAN_FAILURE,
} from "./types";

export const getAllSammelans = () => async (dispatch) => {
  try {
    const res = await axios.get("/sammelan/all");

    dispatch({
      type: GET_SAMMELAN_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_SAMMELAN_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const addNewSammelan = (body) => async (dispatch) => {
  try {
    const res = await axios.post("/sammelan/new", body);

    dispatch({
      type: ADD_NEW_SAMMELAN_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ADD_NEW_SAMMELAN_FAIL,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const deleteSammelan = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/sammelan/${id}`);

    dispatch({
      type: SAMMELAN_DELETE_SUCCESS,
      payload: { msg: res.data.msg, id },
    });
  } catch (error) {
    dispatch({
      type: SAMMELAN_DELETE_FAILED,
      payload: error.response?.data.msg || error.message,
    });
  }
};

export const updateSammelan = (id, body) => async (dispatch) => {
  try {
    const res = await axios.patch(`/sammelan/${id}`, body);

    dispatch({
      type: SAMMELAN_UPDATED_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SAMMELAN_UPDATED_FAILED,
      payload: error.response?.data.msg || error.message,
    });
  }
};


export const setSelectedSammelan = (sammelanId) => {
  return {
    type: SET_SELECTED_SAMMELAN,
    payload: sammelanId,
  };
};

export const fetchSelectedSammelan = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/sammelan/sammelan/selected");
      const selectedSammelanId = response.data.selectedSammelan;
      dispatch({
        type: GET_SELECTED_SAMMELAN_SUCCESS,
        payload: selectedSammelanId,
      });
    } catch (error) {
      dispatch({
        type: GET_SELECTED_SAMMELAN_FAILURE,
        payload: error.message,
      });
    }
  };
};