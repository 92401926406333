import {
  CMS_FILES_UPLOAD_SUCCESS,
  CMS_FILES_UPLOAD_FAIL,
  CMS_FILES_GET_SUCCESS,
  CMS_FILES_GET_FAIL,
  CMS_FILES_DELETE_SUCCESS,
  CMS_FILES_DELETE_FAIL,
  UPDATE_CASTE_SUCCESS,
  UPDATE_CASTE_FAIL,
  UPDATE_GOTRA_SUCCESS,
  UPDATE_GOTRA_FAIL,
  DELETE_GOTRA_SUCCESS,
  DELETE_GOTRA_FAIL,
} from "./types";

const initialState = {
  picturesArray: null,
  casteArray: null,
  gotraArray: [],
  error: "",
  success: "",
};

const CMS = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CMS_FILES_UPLOAD_SUCCESS:
      return {
        ...state,
        success: "Files Uploaded",
        picturesArray: payload,
        error: "",
      };
    case CMS_FILES_UPLOAD_FAIL:
      return { ...state, error: payload, success: "" };
    case CMS_FILES_GET_SUCCESS:
      return {
        ...state,
        error: "",
        success: "",
        picturesArray: payload[0].link,
        casteArray: payload[0].caste,
        gotraArray: payload[0].gotra,
      };
    case CMS_FILES_GET_FAIL:
      return { ...state, error: payload, success: "" };
    case CMS_FILES_DELETE_SUCCESS:
      return {
        ...state,
        error: "",
        success: "File(s) deleted",
        picturesArray: payload,
      };
    case CMS_FILES_DELETE_FAIL:
      return { ...state, error: payload, success: "" };
    case UPDATE_CASTE_SUCCESS:
      return {
        ...state,
        error: "",
        success: "Updated Caste data",
        casteArray: payload,
      };
    case UPDATE_CASTE_FAIL:
      return { ...state, error: payload, success: "" };

    case UPDATE_GOTRA_SUCCESS:
      return {
        ...state,
        error: "",
        success: "Updated Gotra data",
        gotraArray: payload,
      };
    case DELETE_GOTRA_SUCCESS:
      return {
        ...state,
        error: "",
        success: "Updated Gotra data",
        gotraArray: state.gotraArray.filter((gotra) => gotra !== payload),
      };
    case UPDATE_GOTRA_FAIL:
      return { ...state, error: payload, success: "" };
    default:
      return state;
  }
};

export default CMS;
