import {
  GET_ALL_IMAGE_APPROVALS,
  IMAGE_APPROVE_SUCCESS,
  IMAGE_APPROVE_FAIL,
  IMAGE_DELETE_SUCCESS,
  IMAGE_DELETE_FAIL,
  GET_ALL_APPROVALS,
  ALL_DATA_APPROVE_SUCCESS,
  ALL_DATA_APPROVE_FAIL,
} from "./types";

const initialState = {
  allImageApprovals: null,
  allDataApprovals: null,
  error: "",
  success: "",
};

const Approvals = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_IMAGE_APPROVALS:
      return { ...state, allImageApprovals: payload, error: "", success: "" };
    case IMAGE_APPROVE_SUCCESS:
      return {
        ...state,
        allImageApprovals: state.allImageApprovals.filter(
          (approval) => approval._id !== payload
        ),
        success: "Image approved successfully",
        error: "",
      };
    case GET_ALL_APPROVALS:
      return { ...state, allDataApprovals: payload };
    case IMAGE_APPROVE_FAIL:
      return { ...state, error: payload, success: "" };
    case IMAGE_DELETE_SUCCESS:
      return {
        ...state,
        success: "Profile not approved success",
        error: "",
        allImageApprovals: state.allImageApprovals.filter(
          (approval) => approval._id !== payload
        ),
      };
    case IMAGE_DELETE_FAIL:
      return { ...state, success: "", error: payload };

    case ALL_DATA_APPROVE_SUCCESS:
      return {
        ...state,
        allDataApprovals: state.allDataApprovals.filter(
          (approval) => approval.id !== payload.id
        ),
      };
    case ALL_DATA_APPROVE_FAIL:
      return { ...state, error: payload, success: "" };
    default:
      return state;
  }
};

export default Approvals;
